<script setup lang="ts">
import type { MinimalNoun } from '~/src/classes.ts';
import { nounTemplates } from '~/src/data.ts';
import { availableGenders, gendersWithNumerus } from '~/src/nouns.ts';

const props = withDefaults(defineProps<{
    templateBase?: string;
    filter?: string;
}>(), {
    templateBase: '',
    filter: '',
});

const config = useConfig();

const templates = computed((): (MinimalNoun & { id: string })[] => {
    return nounTemplates.filter((template) => {
        for (const field of gendersWithNumerus) {
            for (const value of template[field]) {
                if (value.toLowerCase().includes(props.filter)) {
                    return true;
                }
            }
        }
    }).map((template, i) => {
        return {
            id: `template-${i}`,
            ...template.fill(props.templateBase || '-'),
        };
    });
});
</script>

<template>
    <Table ref="table" :data="templates" fixed>
        <template #header>
            <div v-for="gender in availableGenders(config)" :key="gender" class="d-none d-md-block bold">
                <NounsGenderLabel :gender="gender" />
            </div>
        </template>
        <template #row="{ el: noun }">
            <NounsTableEntry :noun>
                <template #buttons>
                    <ul class="list-unstyled list-btn-concise">
                        <slot name="buttons" :template="noun"></slot>
                    </ul>
                </template>
            </NounsTableEntry>
        </template>

        <template #empty>
            <Icon v="search" />
            <T>nouns.empty</T>
        </template>
    </Table>
</template>
